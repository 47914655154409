@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* .b1:hover {
  box-shadow: 5px 10px 25px 25px rgb(0, 0, 0);
} */
@media (min-width: 768px) {
  .mentor-card {
    width: 45%; /* Adjust width as per your design */
  }
}



.drop-head {
  position: relative;
  display: inline-block;
  padding: 0.75rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 20px;
}
.font-poppins {
  font-family: 'Poppins', sans-serif;
}


.drop-head:hover {
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 0.75rem 1.25rem;
}

.drop-head:hover .drop-items {
  display: block;
}

.drop-items {
  display: none;
  position: absolute;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 12px;
  border-radius: 20px;
  border-top-right-radius: 0px;
  z-index: 1;
  right: 0%;
}

.item {
  font-size: 15px;
  margin: 8px 0;
  padding: 5px;
}

.item:hover {
  background-color: black;
  color: white;
  border-radius: 5px;
}

.arrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  position: relative;
  bottom: 4px;
  margin-left: 8px;
}

.drop-head:hover .arrow {
  border-color: rgb(255, 255, 255);
  -webkit-transform: rotate(225deg);
  top: 1px;
}

/* .dots{
  display: none;
}

.rm:hover .dots{
  display: block;
} */

/* 

top-[190px] => top-[190.5px]

    bottom-[169px]

.b1:hover{
  background-color: #ee9ced;
}

.b1:hover .bc1{
  background-color: #ee9ced ;
}

background-color: #c40a83;
background-color: #59127d;
background-color:mediumorchid;

background-image: linear-gradient(#59127d,#c40a83); 



810f80
d676d5
e88ae7
ee9ced

.search:hover{
  background-color:mediumorchid;
}
................................................................................................
.simg1{
    position: absolute;
    animation-name: example;
    animation-duration: 2s;
    animation-delay: 5s;
    
  }
  
  .simg2{
    position: absolute;
    animation-name: example;
    animation-duration: 2s;
    animation-delay: 5s;
  
  }
  
  .simg3{
    position: absolute;
    animation-name: example;
    animation-duration: 2s;
    animation-delay: 5s;
  
  }
  
  .simg4{
    position: absolute;
    animation-name: example;
    animation-duration: 2s;
    animation-delay: 5s;
  
  }
  
  @keyframes example {
    from {
      bottom: -450px;
    }
    to {
      bottom: 0px;
    }
  }
  
  .head{
    animation-name: title;
    animation-duration: 2s;
      animation-iteration-count: infinite;
    
  }
  
  @keyframes title {
    from{
      left: -150px;
      opacity: 0;
    }
    to{
      left: 150px;
      opacity: 1;
    }
  }
  
  */
